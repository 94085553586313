import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
@Component({
  selector: 'app-single-selection',
  templateUrl: './single-selection.component.html',
  styleUrls: ['./single-selection.component.scss'],
})
export class SingleSelectionComponent implements OnInit {

  countryCtrl: any;
  searchedText: any;
  filteredData: any = [];
  @Input() country: any = [];

  constructor(public popoverCtrl: PopoverController) { }

  ngOnInit() {
    this.filteredData = this.country;
  }

  onInputChange() {
    this.filteredData = this.country.filter((option) => option.value.toLowerCase().includes(this.searchedText.toLowerCase())).map(option => option);
  }

  onSelectionChanged() {
    this.dismiss(this.countryCtrl);
  }

  async dismiss(countryCode) {
    var countryName = this.country.find((option) => option.key == countryCode);
    await this.popoverCtrl.dismiss({ code: countryCode, name: countryName.value }, 'selected');
  }


}
