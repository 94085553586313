import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController, PopoverController, ToastController } from '@ionic/angular';
import { MessageModelPage } from 'src/app/common/pages/message-model/message-model.page';

import { RegistrationService } from 'src/app/registration/services/registration.service';
import { OtpPage } from 'src/app/otp/pages/otp/otp.page';
import { LoadingService } from 'src/app/common/services/loading/loading.service';
import { AuthHelperService } from 'src/app/common/services/auth-helper/auth-helper.service';
import { PageService } from 'src/app/common/services/page/page.service';
import { ToastService } from 'src/app/common/services/toast/toast.service';
import { environment } from 'src/environments/environment';
import { FilePage } from 'src/app/file/pages/file.page';
import { RegistrationPage } from 'src/app/registration/pages/registration.page';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { AuthService, FacebookLoginProvider, GoogleLoginProvider } from 'angular-6-social-login';
import { SingleSelectionComponent } from '../single-selection/single-selection.component';

@Component({
  selector: 'app-registration-component',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  logo: any;
  public static pageId: number = 4;
  pageData: any = [];
  backgroundImage: string = environment.bannerDefault;
  profileChoosed: boolean = false;
  public regForm: FormGroup;
  passwordIconOff: boolean = true;
  showPassword: boolean = false;
  confo: boolean = false;
  image: any = null;
  previewImage: any;
  eventList: any = [];
  public countryFilterCtrl: FormControl = new FormControl();
  filteredOptions: Observable<any[]>;
  country: any = [];

  constructor(private formBuilder: FormBuilder, public router: Router,
    public modalController: ModalController,
    public loadingService: LoadingService, public authHelperService: AuthHelperService,
    public toastController: ToastController, public registerServie: RegistrationService,
    public pageService: PageService, public toastService: ToastService,
    private socialAuthService: AuthService, public popoverCtrl: PopoverController) {


    this.regForm = this.formBuilder.group({
      use_name: ['', [Validators.minLength(3)]],
      user_email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$')]],
      mobileNumber: ['', [Validators.pattern('^[0-9]{8,11}$')]],
      company: ['', [Validators.minLength(3)]],
      designation: ['', [Validators.minLength(3)]],
      country: [''],
      countryName: [''],
      // password: ['', [Validators.required, Validators.pattern('^.{7,30}$')]],
      // cpassword: ['', Validators.required],
      privacy: [''],
      evId: ['']
    }
      //, { validator: this.checkIfEndDateAfterStartDate}
    );

    this.pageService.getEventList();
  }

  goToUrl(url) {
    this.modalController.dismiss();
    this.router.navigateByUrl(url);
  }
  ngOnInit() {
    this.regForm.reset();
    // if (this.router.getCurrentNavigation().extras.state) {
    //   this.regForm.patchValue({
    //     user_email: this.router.getCurrentNavigation().extras.state.user_email
    //   });
    // }
    this.getInitData();

    this.pageService.getSiteInfo().then(value => {
      this.logo = value.logo;
    });
  }

  loginPage() {
    this.pageService.goTologin();
  }

  getInitData() {
    this.pageService.storePageData(RegistrationPage.pageId).then(value => {
      this.pageData = value;
      var sortable = [];
      for (var item in this.pageData.pageData.country) {
        sortable.push({ key: item, value: this.pageData.pageData.country[item] });
      }
      this.country = sortable.sort((a, b) => (a.value > b.value) ? 1 : -1);
      if (this.pageData.page_component != null) {
        this.pageData.page_component.forEach(element => {
          if (element.paComponentType == "image") {
            if (element.image.identifier == 'bg-image') {
              this.backgroundImage = element.image.atta_url;
            }
          }
        });
      }
      this.initFilter();
    });
  }

  initFilter() {
    this.filteredOptions = this.countryFilterCtrl.valueChanges.pipe(
      startWith(''),
      map(value => this.country.filter(option => option.value.toLowerCase().includes(value.toLowerCase())))
    );
  }

  hideShowPassword() {
    this.passwordIconOff = this.passwordIconOff ? false : true;
    this.showPassword = this.showPassword ? false : true;
  }


  async showModel(data) {
    const modal = await this.modalController.create({
      component: MessageModelPage,
      cssClass: 'msgpop',
      backdropDismiss: false,
      componentProps: {
        'titleData': 'Successfully Registered',
        'msgtype': 'sucess',
        'temptype': 'message',
        'messageData': "The 4 digit verfication code has been sent to the email. Make sure to check your spam box in case it gets filtered. Click OK to enter the verification code"
      }
    });

    modal.onDidDismiss().then((value: any) => {
      this.showModal(data);
    });
    return await modal.present();

  }

  onpasswordChange() {
    if (this.regForm.value.cpassword != this.regForm.value.password) {
      this.confo = true;
    } else {
      this.confo = false;
    }
  }

  async showModal(data) {
    const modal = await this.modalController.create({
      component: OtpPage,
      cssClass: 'msgpop bigspo wd',
      backdropDismiss: false,
      componentProps: {
        'messageData': 'Your pre-registered login ID is not registered as visitor for this current show. Please proceed here to register!',
        'userId': data.id,
        'userEmail': data.user_email.substring(0, 4)
      }
    });
    modal.onDidDismiss().then((data) => {
      this.showModalotp(data.data.value);
    });

    return await modal.present();
  }

  async showModalotp(tokenData) {
    const modal = await this.modalController.create({
      component: MessageModelPage,
      backdropDismiss: false,
      cssClass: 'msgpop',
      componentProps: {
        'titleData': 'Successfully verified',
        'msgtype': 'sucess',
        'temptype': 'social',
        'messageData': "Invite your friends by sharing in social media"
      }
    });

    modal.onDidDismiss().then((data) => {
      this.setToken(tokenData);
    });

    return await modal.present();
  }

  onRegisterClicked(verify: boolean) {
    if (this.regForm.valid && this.regForm.value.privacy) {
      this.loadingService.present();
      // if (this.regForm.value.password == this.regForm.value.cpassword) {
      // } else {
      //   this.toastService.presentToast({ message: "Passwords doesn't match", status: 'error' });
      // }
      this.regForm.value.name = this.regForm.value.use_name;
      this.regForm.value.isverify = verify;
      var myFormData = new FormData();
      myFormData.append('attachment', this.image);
      myFormData.append('others', JSON.stringify(this.regForm.value));
      this.registerServie.registerUser(myFormData).subscribe(value => {
        this.loadingService.dismiss();
        if (verify) { this.modalController.dismiss(); this.showModel(value); }
        else this.setToken(value.access_token);
        this.regForm.reset();
      }, error => {
        this.loadingService.dismiss();
        if ("message" in error.error) {
          this.toastService.presentToast({ message: error.error.message, status: 'error' });
        } else {
          this.toastService.presentToast({ message: JSON.stringify(error.error.error.user_email), status: 'error' });
        }
      });
    }
    else if (!this.regForm.value.privacy) {
      this.toastService.presentToast({ message: "Please agree the Privacy Policy", status: 'error' });
    }
  }

  setToken(value) {
    this.authHelperService.setToken(value.token.access_token);
    this.pageService.storeValue(value.userData, 'userInfo');
    this.modalController.dismiss({ status: true });
    this.pageService.redirect();
  }

  async uploadPictureClicked() {
    const modal = await this.modalController.create({
      component: FilePage,
      cssClass: 'file-popup',
      componentProps: {
        'chooseFile': false,
        'postLogin': false
      }
    });

    modal.onDidDismiss().then((data) => {
      if (data.data != undefined) {
        this.profileChoosed = true;
        this.image = data.data.file;
        this.previewImage = data.data.image;
      }
    });

    return await modal.present();
  }

  async viewPrivacy() {
    const modal = await this.modalController.create({
      component: PrivacyPolicyComponent,
      cssClass: 'popup-xl',
    });

    modal.onDidDismiss().then((data) => {
    });
    return await modal.present();
  }

  public socialSignIn(socialPlatform: string) {
    let socialPlatformProvider;
    var event = this.pageService.getValue('event');
    if (socialPlatform == "facebook") {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform == "google") {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }

    this.socialAuthService.signIn(socialPlatformProvider).then(
      (userData) => {
        userData['evId'] = event.id;
        this.loadingService.present();
        this.registerServie.socialLogin(userData).subscribe(value => {
          this.loadingService.dismiss();
          this.checkUserValues(value);
        }, error => {
          this.loadingService.dismiss();
          this.toastService.presentToast({ message: error.error, status: 'error' });
        });
      }
    );
  }

  checkUserValues(value) {
    if (value.status) {
      this.setToken(value);
    } else {
      if (!value.userData.user_data.OTPverified) {//user not verified
        this.dismiss();
        this.showModal(value.userData);
      }
      else {//User blocked       
        this.toastService.presentToast({ message: value.message, status: 'error' });
        this.dismiss();
      }
    }
  }

  async openCountryPopup() {
    const popover = await this.popoverCtrl.create({
      component: SingleSelectionComponent,
      componentProps: { country: this.country },
      cssClass: 'select-popup'
    });
    await popover.present();

    await popover.onDidDismiss().then(data => {
      if (data.role == 'selected' && data.data) {
        this.regForm.patchValue({
          country: data.data.code,
          countryName: data.data.name + ' (+' + data.data.code + ')'
        });
      }
    });
  }

  dismiss() {
    this.modalController.dismiss();
  }
}