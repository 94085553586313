import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AssociationsListPageRoutingModule } from './associations-list-routing.module';

import { AssociationsListPage } from './associations-list.page';
import { ComponentsModule } from 'src/app/components/components.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ComponentsModule,
    AssociationsListPageRoutingModule
  ],  
  declarations: [AssociationsListPage],
  schemas: [    
    CUSTOM_ELEMENTS_SCHEMA,   
    NO_ERRORS_SCHEMA,
  ],
})
export class AssociationsListPageModule {}
