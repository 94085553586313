import { Component, OnInit, Pipe, Input, ElementRef, PipeTransform, Renderer2, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../services/toast/toast.service';
import { StallService } from 'src/app/stall/services/stall.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthHelperService } from '../../services/auth-helper/auth-helper.service';

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}



@Component({
  selector: 'app-video-model',
  templateUrl: './video-model.page.html',
  styleUrls: ['./video-model.page.scss'],
})
export class VideoModelPage implements OnInit {

  constructor(
    private modalCtrl: ModalController,
    public route: Router, public stallService: StallService,
    public toastService: ToastService, public authHelperservice: AuthHelperService) { }

  @Input() titleData: string;
  @Input() messageData: any;
  @Input() msgtype: string;
  @Input() user: any;
  @Input() showItems: boolean = true;
  @Input() multiple: boolean = true;
  @Input() videoList: any;

  video: any;
  curentvideo: any;

  ngOnInit() {
    this.video = this.videoList;
    if (this.multiple) {
      this.curentvideo = 'https://www.youtube.com/embed/' + this.YouTubeGetID(this.video[0].furl) + "?autoplay=1&rel=0";
      this.setActive(0);
    } else if (!this.multiple) {
      this.curentvideo = 'https://www.youtube.com/embed/' + this.YouTubeGetID(this.video.url) + "?autoplay=1&rel=0";
    }
  }

  YouTubeGetID(url) {
    var ID = '';
    url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
      ID = url[2].split(/[^0-9a-z_\-]/i);
      ID = ID[0];
    }
    else {
      ID = url;
    }
    return ID;
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }
  enternowdismiss() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  playvideo(link) {
    this.curentvideo = 'https://www.youtube.com/embed/' + this.YouTubeGetID(this.video[link].furl) + "?autoplay=1";
    this.setActive(link);
  }

  setActive(index) {
    this.video.forEach(element => {
      element.active = false;
    });
    this.video[index].active = true;
    this.visit(this.video[index].fid);
  }

  visit(assetId) {
    this.stallService.saveVisit({ 'useId': this.authHelperservice.getUserId(), 'pageType': 'video', 'pageTypeId': this.messageData.stId, 'assId': assetId }).subscribe(value => {
    }, error => {
      console.log(error);
    });
  }
}