import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { PageService } from '../../services/page/page.service';
import { NavigationExtras } from '@angular/router';
import { PopoverController, NavController } from '@ionic/angular';
import { ToastService } from '../../services/toast/toast.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent implements OnInit {

  messageList: any = [];
  readMessages: any = [];
  constructor(public pageService: PageService, public popoverController: PopoverController,
              public navCtrl: NavController, public toastService: ToastService) { 
    this.getMessages();
  }

  ngOnInit() {}

  getMessages()
  {
    this.pageService.getNotifications('unread').then(value => {
      this.messageList = value[0];
      this.readMessages = value[1]
      this.messageList.reverse();
      this.readMessages.reverse();
    });
  }

  async onNotificationClicked(item) {
    await this.popoverController.dismiss();
    if(item.status == 'unread') {
      this.toastService.changeStatus(item, 'read');
    }
    if(item.type == 'network') {
      this.pageService.goChatModel(item);
    } else if(item.type == 'video' && item.status == 'unread') {
      this.pageService.goToVideoChat(item);
    } else {
      this.pageService.goToStall(item);
    }
  }

  async showRecentActivity() {
    await this.popoverController.dismiss();
    this.navCtrl.navigateForward(['user/activity']);
  }

}

@Pipe({
  name: 'dateAgo',
  pure: true
})
export class DateAgoPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
        return 'Just now';
      const intervals = {
        'year': 31536000,
        'month': 2592000,
        'week': 604800,
        'day': 86400,
        'hour': 3600,
        'minute': 60,
        'second': 1
      };
      let counter;
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0)
          if (counter === 1) {
            return counter + ' ' + i + ' ago'; // singular (1 day ago)
          } else {
            return counter + ' ' + i + 's ago'; // plural (2 days ago)
          }
      }
    }
    return value;
  }

}