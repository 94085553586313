// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


var host = location.hostname;
var url = '';


if (host.includes("complastme.smartvirtualexpos")) {
  url = 'https://complastme.smartvirtualexpos.com/site/public/';
}
else if (host.includes("beta.smartvirtualexpos")) {
  url = 'https://beta.smartvirtualexpos.com/site/public/';
}
else if (host.includes("womms.smartvirtualexpos")) {
  url = 'https://womms.smartvirtualexpos.com/site/public/';
}
else if (host.includes("smartvirtualexpos")) {
  url = 'https://api.smartvirtualexpos.com/public/';
}
else if (host.includes("stag")) {
  url = 'https://stag-veapi.kiluvai.co.in/public/';
}
else if (host.includes("dev")) {
  url = 'https://dev-veapi.kiluvai.co.in/public/';
}
else if (host.includes("beta.ismartexpos")) {
  url = 'https://beta.ismartexpos.com/site/public/';
}
else if (host.includes("agoexpo.ismartexpos")) {
  url = 'https://api.ismartexpos.com/public/';
}
else if (host.includes("localhost")) {
  url = 'https://womms.smartvirtualexpos.com/site/public/';
}
else {
  url = 'https://dev-veapi.kiluvai.co.in/public/';
}

url = 'https://womms.smartvirtualexpos.com/site/public/';
//url = 'http://127.0.0.1:8000/';

export const environment = {
  production: false,
  baseUrl: url + 'api',
  bannerDefault: url + 'storage/20200626_0716481593155808090906900.jpg',
  firebaseConfig: {
    apiKey: "AIzaSyDhigQQT9M0Hev6E_aZWxF9UE8CAXpZvQo",
    authDomain: "ismartexpos-annual.firebaseapp.com",
    databaseURL: "https://ismartexpos-annual-default-rtdb.firebaseio.com/",
    projectId: "ismartexpos-annual",
    storageBucket: "ismartexpos-annual.appspot.com",
    messagingSenderId: "108033129949",
    appId: "1:108033129949:web:963f2d92e27f4777608a34",
    measurementId: "G-MSDZHBT3XR"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

