import { Component, OnInit, Input } from '@angular/core';
import { PageService } from '../../services/page/page.service';
import { Router } from '@angular/router';
import { AuthHelperService } from '../../services/auth-helper/auth-helper.service';
import { ToastService } from '../../services/toast/toast.service';
import { AlertController, NavController, PopoverController } from '@ionic/angular';
import { AlertService } from 'src/app/common/services/alert/alert.service';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/user/services/user.service';
import { LoadingService } from '../../services/loading/loading.service';
import { NotificationListComponent } from '../notification-list/notification-list.component';
import * as firebase from 'firebase';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input() user: any = [];
  pageId: number = 9;
  pageData: any = [];
  event: any;
  pageURL: string;
  events: boolean = false;
  logo: any;
  userId: number;
  briefCase: any = [];
  notificationCount: number = 0;
  userRoles: any = [];
  backgroundImage: string = environment.bannerDefault;  

  menuType:any = 1;

  constructor(public pageService: PageService, public router: Router, public userService: UserService,
    public authHelperService: AuthHelperService, public alertService: AlertService,
    public toastService: ToastService, public alertController: AlertController,
    public navCtrl: NavController, public loadingService: LoadingService,
    public popoverController: PopoverController) {
    if (this.authHelperService.getToken() != null) this.pageService.getNotificationCount();
    this.userId = this.authHelperService.getUserId();
    if (this.pageService.getValue('event')) {
      this.events = true;
    }

    if (this.authHelperService.getToken() != null) {
      this.getUserProfile();
      this.getBriefCase();
      this.getNotifiCount();
    }
    this.getInitData();
    this.userRoles = this.authHelperService.getRoles();
  }

  ngOnInit() {
    this.pageURL = this.router.url;

    if(this.pageURL == '/lobby' ||
    this.pageURL == '/category' ||
    this.pageURL == '/associations-list' ||
    this.pageURL == '/associations-lobby' ||
    this.pageURL == '/countries-list' ||
    this.pageURL == '/countries-lobby'
    ){
      this.menuType = 2;
    }
  }

  exitExpo() {
    this.pageService.removeValue('event');
    this.navCtrl.navigateRoot('home');
  }


  onLogoutClicked() {
    this.alertService.logoutAlert();
  }

  getInitData() {

    this.pageService.getSiteInfo().then(value => {
      this.logo = value.logo;
    });

    this.event = this.pageService.getValue('event');
    this.pageService.getPageData(this.pageId).subscribe(value => {
      this.pageData = value;
    }, error => {
      console.log(error);
    });
  }

  getUserProfile() {
    this.pageService.getUserProfile().then(value => {
      this.user = value;
    });
  }

  getBriefCase() {
    this.userService.getMyBrief(this.userId).subscribe(value => {
      this.briefCase = value.data;
    }, error => {
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    });
  }

  onDownloadDataClicked() {
    this.loadingService.present();
    this.userService.downloadData().subscribe(value => {
      this.loadingService.dismiss();
      const blob = new Blob([value], { type: 'application/zip' });
      var downloadURL = window.URL.createObjectURL(value);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = "files.zip";
      link.click();
      this.toastService.presentToast({ message: 'Files downloaded', status: 'success' });
    }, error => {
      this.loadingService.dismiss();
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    });
  }

  getNotifiCount() {
    let event = this.pageService.getValue('event');
    if (event != null) {
      firebase.database().ref('notifications').child(this.authHelperService.getUserId()).on('value', (snapshot) => {
        var res = {};
        res = snapshot.val();
        this.notificationCount = 0;
        for (let i in res) {
          if (res[i].status == 'unread' && res[i].eventId == event.id) {
            ++this.notificationCount;
          }
        }
      });
    }
  }

  async onNotificationClicked() {
    const popover = await this.popoverController.create({
      component: NotificationListComponent,
      cssClass: 'cus-popover',
      translucent: true
    });
    return await popover.present();
  }

  saveRoute(route) {
    this.pageService.saveRoute('router', route);
  }

  checkRole(role) {
    if (!this.userRoles || this.userRoles == undefined || this.userRoles == null)
      this.userRoles = this.authHelperService.getRoles();
    if (this.userRoles && this.userRoles != undefined && this.userRoles != null)
      return this.userRoles.includes(role) ? true : false;
    return false;
  }

  backToLobby(lobby) {
    if (lobby == 1) {
      this.pageService.removeValue('cat_level2');
      this.pageService.removeValue('cat_level1');
      this.navCtrl.navigateRoot('home');
    } else if (lobby == 2) {
      var level1 = this.pageService.getValue('cat_level1');
      if (level1 != null && level1 != undefined) {
        if (level1.type == 'womms') this.navCtrl.navigateRoot('category');
        if (level1.type == 'associations') this.navCtrl.navigateRoot('associations-list');
        if (level1.type == 'country') this.navCtrl.navigateRoot('countries-list');
        this.pageService.removeValue('cat_level2');
      } else this.navCtrl.navigateRoot('home');
    }
  }

}
