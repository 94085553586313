import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-preview-page',
  templateUrl: './preview-page.component.html',
  styleUrls: ['./preview-page.component.scss'],
})
export class PreviewPageComponent implements OnInit {

  @Input() pageURL: string;

  constructor() { }

  ngOnInit() { }

}
