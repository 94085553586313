import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/base.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AssociationService extends BaseService {

  constructor(http: HttpClient) {
    super(http);
  }

  getAllAssociation() {
    return super.get<any>('associations', true);
  }

}