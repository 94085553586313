import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/base.service';
import { HttpClient } from '@angular/common/http';
import { stdin } from 'process';

@Injectable({
  providedIn: 'root'
})
export class StallService extends BaseService {

  constructor(http: HttpClient) {
    super(http);
  }

  getStall($exid, $evtd) {
    return super.get<any>('stall/' + $exid + '/' + $evtd, true);
  }

  brifStore(data) {
    return super.post<any>('brief', true, data);
  }

  saveFeedback(data) {
    return super.post<any>('stall-feedback', true, data);
  }

  saveEnquiry(data) {
    return super.post<any>('enquiry', true, data);
  }

  saveVisit(data) {
    return super.post<any>('visit', true, data);
  }

  getScheduledMeetings(evId, stId) {
    return super.get<any>('schedule-meeting/' + evId + '/' + stId, true);
  }

  saveMeeting(data) {
    return super.post<any>('schedule-meeting', true, data);
  }

  textChatNotification(data) {
    return super.post<any>('text-chat', true, data);
  }

  videoChatNotification(data) {
    return super.post<any>('video-chat', true, data);
  }

  createZoomMeet(data) {
    return super.post<any>('zoom-meeting', true, data);
  }
}