import { Component, OnInit, Input } from '@angular/core';
import { ToastService } from '../../services/toast/toast.service';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { StallService } from 'src/app/stall/services/stall.service';

@Component({
  selector: 'app-enquiry',
  templateUrl: './enquiry.page.html',
  styleUrls: ['./enquiry.page.scss'],
})
export class EnquiryPage implements OnInit {

  enquiryForm: FormGroup;
  @Input() stall: any;

  constructor(private formBuilder: FormBuilder, private modalCtrl: ModalController,
              public toastService: ToastService, public stallService: StallService) 
  {
    this.enquiryForm = this.formBuilder.group({
      description: ['', Validators.required],
      stId: ['']
    });
  }

  ngOnInit() {
  }

  onSendClicked()
  {
    this.enquiryForm.value.stId = this.stall;
    this.toastService.presentToast({ message:'Our team will Contact you soon' });  
    this.dismiss();
    this.stallService.saveEnquiry(this.enquiryForm.value).subscribe(value => {
    }, error => {
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    });
  }

  dismiss() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

}