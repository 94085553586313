import { Component } from '@angular/core';
import { PageService } from 'src/app/common/services/page/page.service';
import { HomeService } from '../../services/home.service';
import { ToastService } from 'src/app/common/services/toast/toast.service';
import { ModalController } from '@ionic/angular';
import { CategoryPage } from '../../../common/pages/category/category.page';
import { Router } from '@angular/router';
import { CategoryComponent } from 'src/app/common/components/category/category.component';
import { AlertService } from 'src/app/common/services/alert/alert.service';
import { AuthHelperService } from 'src/app/common/services/auth-helper/auth-helper.service';
import { environment } from 'src/environments/environment';
import { LobbyPage } from 'src/app/lobby/pages/lobby/lobby.page';
import { UserService } from 'src/app/user/services/user.service';
import { UserPage } from 'src/app/user/pages/user.page';
import { VideoModelPage } from 'src/app/common/pages/video-model/video-model.page';


@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage {

  pageData: any = [];
  eventList: any = [];
  public static pageId: number = 7;
  changeText: any;
  color: string = '';
  logo: any;
  backgroundImage: string = environment.bannerDefault;
  eventCatList: any = [];
  user: any = [];
  userId: number;
  briefCase: any = [];
  userRoles: any = [];

  constructor(public homeService: HomeService, public pageService: PageService,
    public toastService: ToastService, public modalController: ModalController,
    public router: Router, public authHelperService: AuthHelperService,
    public alertService: AlertService, public userService: UserService,
    public userPage: UserPage) {
    this.changeText = true;
    this.userId = this.authHelperService.getUserId();
    this.userRoles = this.authHelperService.getRoles();
  }

  ngOnInit() {
    this.getInitData();
    if (this.authHelperService.getToken() != null) {
      this.getUserProfile();
      this.getBriefCase();
      this.initStatus();
    }
    var highlightedItems = document.querySelectorAll(".f-logo");
    highlightedItems.forEach(function (logolist) {
      logolist.classList.remove("acs");
    });
  }

  ionViewWillEnter() {
    this.getEventList();
    this.pageService.getSiteInfo().then(value => {
      this.logo = value.logo;
    });
    this.changeText = true;
  }

  async onHelpdeskClicked() {
    // const modal = await this.modalController.create({
    //   component: HelpdeskModalPage,
    //   cssClass: 'stallfeedback', 
    //   componentProps: { 
    //     'titleData': 'inquiryForm', 
    //     'page': 'lobby'
    //   }
    // });  

    // setTimeout(function () {
    //   let video = document.querySelector('#lobbyvideo');
    //   var str = video.getAttribute("src");
    //   var res = str.replace("autoplay=1", "autoplay=0");
    //   video.setAttribute("src", res);
    // }, 100);

    const modal = await this.modalController.create({
      component: VideoModelPage,
      cssClass: 'videopopup',
      componentProps: {
        'videoList': { url: 'https://www.youtube.com/watch?v=i9ao48Cnfcs' },
        'multiple': false
      }
    });

    modal.onDidDismiss().then((value: any) => {
      // setTimeout(function () {
      //   let video = document.querySelector('#lobbyvideo');
      //   var str = video.getAttribute("src");
      //   var res = str.replace("autoplay=0", "autoplay=1");
      //   video.setAttribute("src", res);
      // }, 100);
    });
    return await modal.present();
  }

  initStatus() {
    let data = {
      "status": 'offline',
      "stallstatus": 'offline',
      date: Date.now(),
    }
    this.pageService.addUser(data);
  }

  addClass(event): void {

    //  console.log(event.target); 
    //document.querySelectorAll('.f-logo');

    var highlightedItems = document.querySelectorAll(".f-logo");
    highlightedItems.forEach(function (logolist) {
      logolist.classList.remove("acs");
    });
    //.classList.remove("acs");
    this.changeText = false;
    event.srcElement.classList.add("acs");
    //event.target.className += 'myClass';
  }

  removeClass(event): void {
    //document.querySelector('.f-logo').classList.remove("acs");
    // event.srcElement.classList.remove("acs");
    //  event.target.className = event.target.className.replace('myClass', '');
  }
  getInitData() {
    this.pageService.storePageData(HomePage.pageId).then(value => {
      this.pageData = value;
      if (this.pageData.page_component != null) {
        this.pageData.page_component.forEach(element => {
          if (element.paComponentType == "image") {
            if (element.image.identifier == 'bg-image') {
              this.backgroundImage = element.image.atta_url;
            }
          }
        });
      }
    });
    this.pageService.storePageData(LobbyPage.pageId).then(value => { });
  }

  getEventList() {
    this.homeService.getEventList().subscribe(value => {
      this.eventList = value.data;
      if (this.eventList.length <= 0) {
        this.router.navigate(['/info-template'], { skipLocationChange: true });
      } else {
        this.eventList = value.data[0];
        this.pageService.storeValue({ id: this.eventList.eveId, logo: this.eventList.file.atta_url, name: this.eventList.eveName }, 'event');
      }
    }, error => {
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    });
  }

  async showCategoryModal(event) {
    const modal = await this.modalController.create({
      component: CategoryComponent,
      cssClass: 'regpop',
      componentProps: {
        'eventId': event.eveId,
        'eventImg': event.file.atta_url,
        'category': event.event_category
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data != undefined && data.data.reg) {
        this.pageService.storeValue({ id: event.eveId, logo: event.file.atta_url, name: event.eveName }, 'event');
        this.router.navigateByUrl('lobby');
      }
    });
    return await modal.present();
  }

  onEnterNowClicked(event) {
    let userid = this.authHelperService.getUserId();
    let vis = false;
    event.event_registration.forEach(element => {
      if (element.useId == userid && vis != true) {
        vis = true;
      }
    });
    if (vis) {
      this.homeService.sendExhibitorEmail(event.eveId).subscribe(value => { }, error => { console.log(error) });
      this.pageService.storeValue({ id: event.eveId, logo: event.file.atta_url, name: event.eveName }, 'event');
      this.router.navigateByUrl('lobby');
    } else {
      this.showCategoryModal(event);
    }
  }

  onLogoutClicked() {
    this.alertService.logoutAlert();
  }

  onLogoHover(event) {
    console.log('logo hovered' + event.eveId);
  }

  getUserProfile() {
    this.pageService.getUserProfile().then(value => {
      this.user = value;
    });
  }

  getBriefCase() {
    this.userService.getMyBrief(this.userId).subscribe(value => {
      this.briefCase = value.data;
    }, error => {
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    });
  }

  onDownloadDataClicked() {
    this.userPage.onDownloadDataClicked();
  }

  onCategoryClicked(type) {
    if ('event_category' in this.eventList) {
      var category = this.eventList.event_category.find((ele) => ele.category_item.catItemSlug == type);
      if (category) {
        this.pageService.storeValue({ id: category.caiId, name: category.category_item.catItemName, type: 'womms' }, 'cat_level1');
        this.router.navigateByUrl('category');
      }
    }
  }
  onAssociClicked() {
    this.pageService.storeValue({ id: undefined, name: 'Associations', type: 'associations' }, 'cat_level1');
    this.router.navigateByUrl('associations-list');
  }
  onCountryClicked() {
    this.pageService.storeValue({ id: undefined, name: 'Country', type: 'country' }, 'cat_level1');
    this.router.navigateByUrl('countries-list');
  }
}