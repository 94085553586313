import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CountriesLobbyPage } from './countries-lobby.page';

const routes: Routes = [
  {
    path: '',
    component: CountriesLobbyPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CountriesLobbyPageRoutingModule {}
