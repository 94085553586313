import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-colorpic',
  templateUrl: './colorpic.component.html',
  styleUrls: ['./colorpic.component.scss'],
})
export class ColorpicComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
