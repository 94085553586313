import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/base.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HomeService extends BaseService {

  constructor(http: HttpClient) {
    super(http);
  }

  getEventList() {
    return super.get<any>('event/authorized', true);
  }

  eventInsert(data) {
    return super.post<any>('event-reg', true, data);
  }

  sendExhibitorEmail(id) {
    return super.get<any>('visitor/lobby-entry/' + id, true);
  }

}