import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit {

  @Input() sliders: any = [];
  totalCount: number = 0;
  currentCount: number = 0;

  slideConfig = {
    slidesToShow: 1, 
    slidesToScroll: 1,
    nextArrow: "<div class='nav-btn slick-next'>Next</div>",
    prevArrow: "<div class='nav-btn slick-prev'>Prev</div>",
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    asNavFor: '.cus-slick-thumb'
  };
  slideConfig1 = {
    slidesToShow: 6,
    slidesToScroll: 1,
    nextArrow: "<div class='nav-btn slick-next'>Next</div>",
    prevArrow: "<div class='nav-btn slick-prev'>Prev</div>",
    dots: false,
    infinite: true,
    centerMode: false,
    focusOnSelect: true,
    asNavFor: '.cus-slick'
  };

  constructor(public modalCtrl: ModalController) { }

  ngOnInit() {
    this.totalCount = this.sliders.length;
    this.currentCount = 1;
  }

  slickInit(e) {
    
  }

  onModalDismiss() 
  {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  afterSliderChange(event) {
    this.currentCount = event.currentSlide + 1;
  }

}
