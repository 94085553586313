import { Component, OnInit } from '@angular/core';
import { AssociationService } from '../../services/association.service';
import { ToastService } from 'src/app/common/services/toast/toast.service';
import { LoadingService } from 'src/app/common/services/loading/loading.service';
import { PageService } from 'src/app/common/services/page/page.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-associations-list',
  templateUrl: './associations-list.page.html',
  styleUrls: ['./associations-list.page.scss'],
})
export class AssociationsListPage implements OnInit {

  logoList: any;
  constructor(public associationService: AssociationService,
    public loadingService: LoadingService,
    public router: Router,
    public pageService: PageService,
    public toastService: ToastService) { }

  ngOnInit() {
    this.loadingService.present();
    this.associationService.getAllAssociation().subscribe(value => {
      this.logoList = value.data;
      this.loadingService.dismiss();
    }, error => {
      this.loadingService.dismiss();
      this.toastService.presentToast({ message: error.error.message, status: 'error' });
    });
  }

  viewAssLobby(data) {
    this.pageService.storeValue({ id: data.id, name: data.name, logo_url: data.logo_url }, 'association-category');
    this.router.navigate(['associations-lobby']);
  }
}