import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./get-started/pages/get-started.module').then(m => m.GetStartedPageModule),
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/pages/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'register',
    redirectTo: '/',
    pathMatch: 'full',
  },
  {
    path: 'forget-password',
    loadChildren: () => import('./forgot-password/pages/forget-password.module').then(m => m.ForgetPasswordPageModule)
  },
  {
    path: 'file',
    loadChildren: () => import('./file/pages/file.module').then(m => m.FilePageModule),
    canActivate: [AuthGuard],
    // data: { access: 'file' }
  },
  {
    path: 'welcome',
    redirectTo: '/',
    pathMatch: 'full',
    // loadChildren: () => import('./welcome/pages/welcome.module').then(m => m.WelcomePageModule)
  },
  {
    path: 'style-guide',
    loadChildren: () => import('./common/pages/style-guide/style-guide.module').then(m => m.StyleGuidePageModule)
  },
  {
    path: 'otp',
    loadChildren: () => import('./otp/pages/otp/otp.module').then(m => m.OtpPageModule)
  },
  {
    path: 'message-model',
    loadChildren: () => import('./common/pages/message-model/message-model.module').then(m => m.MessageModelPageModule),
  },
  {
    path: 'reset-password/:id',
    loadChildren: () => import('./reset-password/pages/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule),
  },
  {
    path: 'home',
    redirectTo: '/',
    //loadChildren: () => import('./home/pages/home/home.module').then(m => m.HomePageModule),
    //data: { access: 'view-home' }
  },
  {
    path: 'seminar-list',
    loadChildren: () => import('./seminar-list/pages/seminar-list/seminar-list.module').then(m => m.SeminarListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'auditorium/:id',
    loadChildren: () => import('./auditorium/pages/auditorium/auditorium.module').then(m => m.AuditoriumPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'lobby',
    loadChildren: () => import('./lobby/pages/lobby/lobby.module').then(m => m.LobbyPageModule),
  },
  {
    path: 'networking-longue',
    loadChildren: () => import('./networking-longue/pages/networking-longue/networking-longue.module').then(m => m.NetworkingLonguePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('./user/pages/user.module').then(m => m.UserPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'exhibitors',
    loadChildren: () => import('./exhibitor-list/pages/exhibitor-list/exhibitor-list.module').then(m => m.ExhibitorListPageModule)
  },
  {
    path: 'stall',
    loadChildren: () => import('./stall/pages/stall/stall.module').then(m => m.StallPageModule)
  },
  {
    path: 'feedback',
    loadChildren: () => import('./common/pages/feedback/feedback.module').then(m => m.FeedbackPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'enquiry',
    loadChildren: () => import('./common/pages/enquiry/enquiry.module').then(m => m.EnquiryPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'helpdesk-modal',
    loadChildren: () => import('./common/pages/helpdesk-modal/helpdesk-modal.module').then(m => m.HelpdeskModalPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'chat',
    loadChildren: () => import('./chat/pages/chat/chat.module').then(m => m.ChatPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'stall-chat',
    loadChildren: () => import('./chat/pages/stall-chat/stall-chat.module').then(m => m.StallChatPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'video-model',
    loadChildren: () => import('./common/pages/video-model/video-model.module').then(m => m.VideoModelPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'meeting-scheduler',
    loadChildren: () => import('./common/pages/meeting-scheduler/meeting-scheduler.module').then(m => m.MeetingSchedulerPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'videochat/landing',
    loadChildren: () => import('./video-chat/pages/landing/landing.module').then(m => m.LandingPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'interface',
    loadChildren: () => import('./interface/pages/interface.module').then(m => m.InterfacePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/pages/admin.module').then(m => m.AdminPageModule),
    canActivate: [AuthGuard],
    data: { access: 'backend-interface' }
  },
  {
    path: 'static',
    loadChildren: () => import('./common/pages/base/base.module').then(m => m.BasePageModule)
  },
  {
    path: 'access-denied',
    loadChildren: () => import('./common/pages/access-denied/access-denied.module').then(m => m.AccessDeniedPageModule)
  },
  {
    path: 'info-template',
    loadChildren: () => import('./common/pages/info-template/info-template.module').then(m => m.InfoTemplatePageModule)
  },
  {
    path: 'category',
    loadChildren: () => import('./lobby/pages/lobby-new/lobby-new.module').then(m => m.LobbyNewPageModule),
  }, {
    path: 'associations-list',
    loadChildren: () => import('./associations/pages/associations-list/associations-list.module').then(m => m.AssociationsListPageModule)
  },
  {
    path: 'associations-lobby',
    loadChildren: () => import('./associations/pages/associations-lobby/associations-lobby.module').then(m => m.AssociationsLobbyPageModule)
  },
  {
    path: 'countries-list',
    loadChildren: () => import('./countries/pages/countries-list/countries-list.module').then(m => m.CountriesListPageModule)
  },
  {
    path: 'countries-lobby',
    loadChildren: () => import('./countries/pages/countries-lobby/countries-lobby.module').then(m => m.CountriesLobbyPageModule)
  },
  {
    path: 'colorpicker',
    loadChildren: () => import('./common/components/colorpicker/colorpicker.module').then(m => m.ColorpickerPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then( m => m.AboutPageModule)
  },
  // {
  //   path: 'sectors',
  //   loadChildren: () => import('./sectors/pages/sectors.module').then(m => m.SectorsPageModule)
  // },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
