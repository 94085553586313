import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthHelperService } from 'src/app/common/services/auth-helper/auth-helper.service';
import { PageService } from 'src/app/common/services/page/page.service';

@Component({
  selector: 'app-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.scss'],
})
export class AdminMenuComponent implements OnInit {

  @Input() menus: any = [];
  eveId: number;
  event: any;

  constructor(public authHelperService: AuthHelperService, public pageService: PageService,
    public router: Router) {
    this.getEvent();
  }

  ngOnInit() { }

  getEvent() {
    this.event = this.pageService.getValue('admin_event');
    if (this.event != null && this.event != undefined) {
      this.eveId = this.event.eveId;
    }
  }

  hasAccess(permission) {
    if (this.authHelperService.checkPermission(permission)) {
      return true
    } else {
      return false;
    }
  }

  isShow(menu) {
    this.event = this.pageService.getValue('admin_event');
    if (!this.isEventRequired(menu.slug) && (this.event == null && this.event == undefined)) {
      return false;
    } else {
      return true;
    }
  }

  isEventRequired(slug) {
    var menus = ['db-management', 'event', 'stall_template', 'role_permission', 'user_registered', 'email_template', 'user', 'report', 'category'];
    return menus.includes(slug) ? true : false;
  }

  onLinkClicked(link) {
    this.getEvent();
    let route = link.replace("{{eveId}}", this.eveId);
    this.router.navigate([route]);
  }

}