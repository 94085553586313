import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {

  message: string;

  constructor(public modalCtrl: ModalController) { }

  ngOnInit() {}

  onCloseClicked() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

}
