import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss'],
})
export class ChatListComponent implements OnInit {

  @Input() userData: any = [];
  
  @Output() onBackClicked = new EventEmitter;
  @Output() onChatClicked = new EventEmitter;

  constructor() { }

  ngOnInit() { }

  onbackclicked() {
    this.onBackClicked.emit();
  }

  goChatModel(user) {
    this.onChatClicked.emit(user);
  }

  createConnection(user) {

  }

}
