import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CountriesListPageRoutingModule } from './countries-list-routing.module';

import { CountriesListPage } from './countries-list.page';
import { ComponentsModule } from 'src/app/components/components.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ComponentsModule,
    CountriesListPageRoutingModule
  ],
  schemas: [    
    CUSTOM_ELEMENTS_SCHEMA,   
    NO_ERRORS_SCHEMA,
  ],
  declarations: [CountriesListPage]
})
export class CountriesListPageModule {}
